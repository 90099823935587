const translations = {
    "en": {
        "AttentionLabel": "Attention",
        "EmailKeyWarning":"Emails must be unique for each user. Make sure no email from the list is already registered as a partner.",
        "Partner": "Partner",
        "PhoneNumberLabel": "Phone Number",
        "ListOfPartners": "List Of Partners",
        "AddPartner":"Add Partner",
        "ListOfDistributors": "List Of Distributors",
        "AddDistributor":"Add Distributor",
        "PartnerRegistrationLabel": "Partner Registration",
        "FillInPartnerDetailsLabel": "Please fill in the partner details below:",
        "RegisterPartnerLabel": "Register Partner",
        "UploadPartnerTemplateLabel": "Upload Partner Template",
        "ServicePartnerLabel":"Service Partner",
        "Distributors": "Distributors",
        "Partners": "Partners",
        "DistributorRegistrationLabel": "Distributor Registration",
        "FillInDistributorDetailsLabel": "Please fill in the distributor details below:",
        "RegisterDistributorLabel": "Register Distributor",
        "UploadDistributorTemplateLabel": "Upload Distributor Template",
        "suggestionsTitle":"User Suggestions",
        "exportLabel":"Export Table",
        "editColumnLabel":"Edit Columns",
        "sendEmailsTitle":"Send Emails",
        "sendEmailsBtn":"New Email",
        "sendEmailsH1":"Email Name",
        "sendEmailsH2":"Sender Name",
        "sendEmailsH3":"Sender Email",
        "sendEmailsH4":"Send Date",
        "sendEmailsH5":"Status",
        "Key": "En",
        "orderTitle": "Orders",
        "usersTitle": "Users",
        "fieldSelect": "Select a field",
        "searchLabel": "Type to search…",
        "policyTitle": "Policies",
        "emailTitle": "Submit Email",
        "search": "Search",
        "SearchingLabel": "Searching...",
        "filters": "Filters:",
        "countrySelection": "Select Country",
        "subBusiness": "Sub Business Segment",
        "dateTitle": "Date",
        "CohId": "COH Customer ID",
        "CohShort": "COH Customer Name",
        "SkfOrderNumber": "COH Order ID",
        "customerOrderNumber": "Customer Order Number",
        "PaymentMethod": "Payment Method",
        "CreditLabel": "Credit Limit",
        "ClearLabel": "Clear",
        "AddOrderLabel": "Add Order",
        "DemoLabel": "With Demo Accounts",
        "NFNumber": "Invoice Number",
        "NFKey": "Invoice Key Access",
        "OrderStatus": "Order Status",
        "AwaitingReg": "Awaiting COH Registration",
        "CancelledLabel": "Cancelled",
        "ProcessingLabel": "Processing",
        "CompletedLabel": "Completed",
        "FunctionLabel": "Functions",
        "AccessDescription": "Admin Panel Access",
        "RegistrationLabel": "Registration",
        "CompaniesLabel": "Companies",
        "UsersLabel": "Users (email)",
        "AvailabilityLabel": "Availability",
        "APIServicesLabel": "API Services",
        "FromToLabel": "From To",
        "QlikviewLabel": "Qlikview Book",
        "OrderbookLabel": "Order Book",
        "PricelistLabel": "Pricelist",
        "PendingTasksLabel": "Pending Tasks",
        "OutOfCatalogLabel": "Out-of-Catalog",
        "CatalogTransitionLabel": "Catalog Translation",
        "ProductDisplayLabel": "Product Display",
        "CatalogOportunityLabel": "Catalog Opportunity",
        "TagManagerLabel": "Tag Manager",
        "ServiceBillingLabel": "Service Billing",
        "ServiceInvoicePjLabel": "Service Invoice Automate PJ",
        "ServicePjOrders": "Service PJ Requests",
        "ReportsLabel": "Report",
        "OthersLabel": "Others",
        "LogoutLabel": "Logout",
        "InvoiceNumberLabel": "Invoice Number",
        "InvoiceKeyLabel": "Invoice Key",
        "BillingDateLabel": "Billing Date",
        "InsertOrderLabel": "Insert Order",
        "FillInLabel": "Please fill the information below:",
        "COHOrderIdLabel": "COH Order ID",
        "VerifyOderLabel": "Verify Order",
        "AddUserLabel": "Add User",
        "FillInUserLabel": "Fill the form below to register a new user:",
        "NameLabel": "Name",
        "EmailLabel": "Email",
        "RoleLabel": "Role",
        "AdminLalbel": "Admin",
        "PasswordLabel": "Password:",
        "ConfirmPasswordLabel": "Confirm Password:",
        "CreateUserLabel": "Add User",
        "ListOfCustomersLabel": "List of Customers",
        "CnpjLabel": "CNPJ",
        "AcceptMktLabel": "Accept E-Mkt",
        "StatusLabel": "Satus",
        "FalseLabel": "FALSE",
        "TrueLabel": "TRUE",
        "InactiveLabel": "Inactive",
        "ActiveLabel": "Active",
        "PendingClientApprovalLabel": "Pending Client Approval",
        "ListOfUsersLabel": "List of Users",
        "CohCompaniesLabel": "COH COH Customer ID",
        "PhoneLabel": "Phone",
        "AwaitingConfLabel": "Awaiting Confirmation",
        "UserRegistrationLabel": "User registration",
        "CustomerNameLabel": "Customer Contact Name",
        "CustomerPhoneLabel": "Customer Contact Phone",
        "CustomerEmailLabel": "Customer Notification Email",
        "RegisterUserLabel": "Register User",
        "DownloadTemplateLabel": "Download Template",
        "DownloadTemplateInstructionsLabel": "Or upload companie's .xlsx Template",
        "DropInstructionsLabel": "DROP HERE OR SELECT A FILE",
        "IDLabel": "ID",
        "ProductDesignationLabel": "Product Designation",
        "QuantityLabel": "Quantity",
        "AdditionalInfoLabel": "Additional Information",
        "RejectedLabel": "Rejected",
        "ExpiredLabel": "Expired",
        "PurchaseCompletedLabel": "Purchase Completed",
        "ApprovedLabel": "Approved",
        "MultipleItemsLabel": "Multiple Items",
        "CreatedServiceLabel": "Created Service",
        "ServiceDescriptionLabel": "Service Description",
        "ServiceOwnerLabel": "Service Owner",
        "ServiceStatusLabel": "Service Status",
        "PreviousLabel": "Previous",
        "NextLabel": "Next",
        "FromLabel": "From",
        "ToLabel": "To",
        "NoDataLabel": "No Data Found",
        "QlikDeletedLabel": "Qlikview Deleted Ids",
        "BulkAddLabel": "Bulk Add ID's",
        "CountryLabel": "Country",
        "TypeLabel": "Type",
        "ReasonLabel": "Reason",
        "AddLabel": "Add",
        "CustomerLabel": "Customer",
        "OrderBookMonitoringLabel": "Order Book Monitoring",
        "DownloadReportLabel": "Download Report",
        "SelectReportLabel": "Select a report",
        "PriceCalculationLabel": "Price Calculation",
        "ProductPriceInstructionLabel": "To view product prices with taxes, please seach for COH COH Customer ID in the search bar.",
        "DownloadErrorsLabels": "Download Errors",
        "NoNetPriceLabel": "No Net Price",
        "NoPriceWithTaxLabel": "No Price With Taxes",
        "PricelistIDLabel": "Price List",
        "AZIdLabel": "AZPartes ID",
        "SKULabel": "SKU",
        "NetPriceLabel": "Net Price",
        "DownloadPriceListLabel": "Price List",
        "PriceWithTaxLabel": "Price With Taxes",
        "GrossPriceLabel": "Gross Price",
        "AllProductsDescriptionLabel": "This tab countains all products that are on the Customers Price List, but are not registered in the catalogs.",
        "DownloadAutoLabel": "Download Automotive SKUs",
        "DownloadIndustrialLabel": "Download Industrial SKUs",
        "CreatedAtLabel": "Created At",
        "LastUpdatesLabel": "Last Updates",
        "NotFoundLabel": "Not Found",
        "BrazilLabel": "Brazil",
        "ArgentinaLabel": "Argentina",
        "OrderLanel": "Order",
        "InvoiceCheckerLabel": "Invoice Checker",
        "ENTLabel": "ENT Status",
        "DelayInBillingLabel": "Delay in Billing",
        "DelayInOrderDeliveryLabel": "Delay in Order Delivery",
        "RBKLabel": "RBK Status",
        "ShipmentDelayLabel": "Shipment Delay",
        "REJLabel": "REJ Status",
        "CANLabel": "CAN Status",
        "NoImageLabel": "No Image",
        "NoRegisterLabel": "No Register",
        "CustomersLabel": "Customers",
        "ProductsLabel": "Products",
        "GlobalXMLDescriptionLabel": "This tab countains the SKF Global XML product descriptions. Descriptions must be translated into English, Spanish and Portuguese.",
        "BulkTranslationLabel": "Bulk Translation",
        "LanguageLabel": "Language",
        "SearchDescriptionLabel": "Type to search a product…",
        "XMLLabel": "XML",
        "EnglishLabel": "English",
        "SpanishLabel": "Spanish",
        "PortugueseLabel": "Portuguese",
        "PendingLabel": "Pending",
        "CompleteLabel": "Complete",
        "ProductManageTabDescription": "This tab manages the products to be displayed on AZPartes.com. The toogles run the following filters:",
        "NoAzIdDescription": "NO AZPARTES ID -> Products not registered in the catalog according to Price List",
        "NoPackDescription": "NO PACK -> Porducts without package code",
        "NoDimensionsDescription": "NO DIMENSIONS -> Products without Dimensions or weight information registered on COH",
        "NoAvailabilityDescription": "NO AVAILABILITY -> Products not available at SKF Brazil (DPC B)",
        "AddPackLabel": "Add Pack Information",
        "DownloadMissingLabel": "Download Missing Dimensions",
        "SegmentLabel": "Segment",
        "NoAZPartesIdLabel": "NO AZPARTES ID",
        "NoPackLabel": "NO PACK",
        "NoDimensionLabel": "NO DIMENSIONS",
        "NoAvailabilityLabel": "NO AVAILABILITY",
        "CatalogLabel": "Catalog",
        "PackLabel": "Pack",
        "DimensionsLabel": "Dimensions",
        "AutomotiveLabel": "Automotive",
        "IndustrialLabel": "Industrial",
        "CatalogDescription": "This tab contains the products that are in the catalogs (automotive and industrial) but are not in any Customer Price List",
        "DownloadDataLabel": "Download Data",
        "CreateTagLabel": "Add Tag",
        "TagNameLabel": "TAG Name",
        "JIRAIDLabel": "JIRA ID",
        "JIRAStatusLabel": "JIRA Status",
        "RequesterNameLabel": "User's Name",
        "RequesterEmailLabel": "User's Email",
        "RequestDateLabel": "Request Date",
        "DetailsLabel": "Details",
        "ServiceTriggerLabel": "Service Invoice Trigger",
        "SourceLabel": "Source",
        "CustomerIDLabel": "COH Customer ID",
        "COWNLabel": "Customer Own Order Number",
        "TextLabel": "Text",
        "EmailToLabel": "Email TO",
        "EmailCCLabel": "Email CC",
        "TotalGrossPriceLabel": "Total Gross Price",
        "TotalQuantityLabel": "Total Quantity",
        "OrderLinesLabel": "Order Lines",
        "ChargeIdLabl": "Charge ID",
        "UnitGrossPriceLabel": "Unit Gross Price",
        "ServiceDepartmentLabel": "Service Department",
        "TotalGrossPricePerLineLabel": "Total Gross Price Per Line",
        "ServiceDpsLabel": "Service Dept",
        "SubmitServiceLabel": "Submit Service Invoice",
        "SearchReportLabel": "Type to search a report",
        "DescriptionLabel": "Description",
        "RequestLabel": "Request",
        "BearingTrackerLabel": "Bearing Tracker",
        "DynamicsLabel": "Dynamics",
        "ErrorLabel": "Error",
        "PasswordMatchLabel": "Passwords Don't Match",
        "ValidEmailLabel": "Insert a valid email",
        "AddedLabel" : "Added!",
        "UserSuccessfullyAddedLabel" : 'User sucessfully added!',
        "SuccessLabel": "Success",
        "UserSuccessfullyCreatedLabel" : 'User sucessfully created!',
        "FailedLabel": "Failed...",
        "CompleteFieldsLabel" : 'Please complete all the required fields',
        "InternalErrorLabel" : 'Internal error, please try again later',
        "AddCustomerLabel" : 'Add Customer',
        "CompanyRegistrationlabel" : "Company Registration",
        "RegisterCompanyLabel" : "Register Company",
        "UploadTemplateLabel" : "Or upload companies .xlsx Template",
        "DropHereLabel" : "DROP HERE",
        "OrLabel" : "OR",
        "SelectAFileLabel": "SELECT A FILE",
        "CreateServiceLabel" : "Add Service",
        "FromToLabel" : "From To",
        "FromLabel" : "FROM",
        "ToLabel" : "TO",
        "SelectAReportLabel" : "Select a Report",
        "DownloadPriceListLabel" : "Download Price List",
        "TranslationTabLabel" : "This tab contains the SKF Global XML product descriptions. Descriptions must be translated into English, Spanish and Portuguese.",
        "MarketingTitle":"Marketing Module",
        "BannersTitle":"Banners",
        "CampaignsTitle":"Campaigns",
        "ProductGuidesTitle":"Product Guides",
    },
    "pt": {
        "AttentionLabel": "Atenção",
        "EmailKeyWarning":"Emails devem ser únicos para cada usuário. Certifique-se que nenhum email da lista já esta cadastrado como parceiro.",
        "Partner": "Parceiro",
        "PhoneNumberLabel": "Número de Telefone",
        "ListOfPartners": "Lista de Parceiros",
        "AddPartner":"Adicionar Parceiro",
        "ListOfDistributors": "Lista de Distribuidores",
        "AddDistributor":"Adicionar Distribuidor",
        "PartnerRegistrationLabel": "Cadastro de Parceiro",
        "FillInPartnerDetailsLabel": "Por favor preencha os detalhes do parceiro abaixo:",
        "RegisterPartnerLabel": "Cadastrar Parceiro",
        "ServicePartnerLabel":"Service Partner",
        "Distributors": "Distribuidores",
        "Partners": "Parceiros",
        "DistributorRegistrationLabel": "Cadastro de Distribuidor",
        "FillInDistributorDetailsLabel": "Por favor preencha os detalhes do distribuidor abaixo:",
        "DistributorNameLabel": "Nome do Distribuidor",
        "DistributorEmailLabel": "Email do Distribuidor",
        "suggestionsTitle":"Sugestões de Usuários",
        "exportLabel":"Exportar Tabela",
        "editColumnLabel":"Editar Colunas",
        "sendEmailsTitle":"Enviar E-mails",
        "sendEmailsBtn":"Novo Email",
        "sendEmailsH1":"Nome do Email",
        "sendEmailsH2":"Nome do Remetente",
        "sendEmailsH3":"Email do Remetente",
        "sendEmailsH4":"Data de Envio",
        "sendEmailsH5":"Status",
        "Key": "Pt",
        "orderTitle": "Pedidos",
        "usersTitle": "Usuários",
        "fieldSelect": "Selecione um campo",
        "searchLabel": "Digite para pesquisar…",
        "policyTitle": "Políticas",        
        "emailTitle": "Enviar Email",
        "search": "Pesquisar",
        "SearchingLabel": "Pesquisando...",
        "filters": "Filtros:",
        "countrySelection": "Selecione o país",
        "subBusiness": "Sub Segmento de Negócio",
        "dateTitle": "Data",
        "CohId": "ID COH",
        "CohShort": "Nome do Cliente COH",
        "NFNumber": "Número da NF",
        "NFKey": "Chave da NF",
        "SkfOrderNumber": "Número de pedido SKF",
        "customerOrderNumber": "Número do pedido do cliente",
        "PaymentMethod": "Método de pagamento",
        "CreditLabel": "Limite de crédito",
        "ClearLabel": "Limpar",
        "AddOrderLabel": "Adicionar pedido",
        "DemoLabel": "Com Contas Demo",
        "OrderStatus": "Status do Pedido",
        "AwaitingReg": "Aguardando Registro do COH",
        "CancelledLabel": "Cancelado",
        "ProcessingLabel": "Em processamento",
        "CompletedLabel": "Concluído",
        "FunctionLabel": "Funções",
        "AccessDescription": "Painel de Administração",
        "RegistrationLabel": "Cadastro",
        "CompaniesLabel": "Empresas",
        "UsersLabel": "Usuários (e-mail)",
        "AvailabilityLabel": "Disponibilidade",
        "APIServicesLabel": "Serviços de API",
        "FromToLabel": "De Para",
        "QlikviewLabel": "Carteira Qlikview",
        "OrderbookLabel": "Carteira de Pedido",
        "PricelistLabel": "Lista de preços",
        "PendingTasksLabel": "Tarefas Pendentes",
        "OutOfCatalogLabel": "Fora do Catálogo",
        "CatalogTransitionLabel": "Tradução do Catálogo",
        "ProductDisplayLabel": "Exibição do Produto",
        "CatalogOportunityLabel": "Oportunidade de Catálogo",
        "TagManagerLabel": "Gerenciador de Tags",
        "ServiceBillingLabel": "Faturamento de Serviços",
        "ServiceInvoicePjLabel": "Automação de Faturamento de Serviços PJ",
        "ServicePjOrders": "Pedidos de Serviço PJ",
        "ReportsLabel": "Relatório",
        "OthersLabel": "Outros",
        "LogoutLabel": "Sair",
        "InvoiceNumberLabel": "Número da NF",
        "InvoiceKeyLabel": "Chave da NF",
        "BillingDateLabel": "Data de faturamento",
        "InsertOrderLabel": "Inserir Pedido",
        "FillInLabel": "Por favor preencha as informações abaixo:",
        "COHOrderIdLabel": "ID do pedido no COH",
        "VerifyOderLabel": "Verifique o Pedido",
        "AddUserLabel": "Adicionar Usuário",
        "FillInUserLabel": "Preencha o formulário abaixo para cadastrar um novo usuário:",
        "NameLabel": "Nome",
        "EmailLabel": "E-mail",
        "RoleLabel": "Cargo",
        "AdminLalbel": "Administrador",
        "PasswordLabel": "Senha:",
        "ConfirmPasswordLabel": "Confirme sua Senha:",
        "CreateUserLabel": "Criar Usuário",
        "ListOfCustomersLabel": "Lista de Clientes",
        "CnpjLabel": "CNPJ",
        "AcceptMktLabel": "Aceita E-Mkt",
        "StatusLabel": "Status",
        "FalseLabel": "FALSO",
        "TrueLabel": "VERDADEIRO",
        "InactiveLabel": "Inativo",
        "ActiveLabel": "Ativo",
        "PendingClientApprovalLabel": "Aprovação do Cliente Pendente",
        "ListOfUsersLabel": "Lista de Usuários",
        "CohCompaniesLabel": "Empresas COH",
        "PhoneLabel": "Telefone",
        "AwaitingConfLabel": "Aguardando confirmação",
        "UserRegistrationLabel": "Cadastro de Usuário",
        "CustomerNameLabel": "Nome de Contato do Cliente",
        "CustomerPhoneLabel": "Telefone de Contato do Cliente",
        "CustomerEmailLabel": "E-mail de notificação do Cliente",
        "RegisterUserLabel": "Cadastrar Usuário",
        "DownloadTemplateLabel": "Baixar Modelo",
        "DownloadTemplateInstructionsLabel": "Ou faça upload do modelo .xlsx da Empresa",
        "DropInstructionsLabel": "SOLTAR AQUI OU SELECIONE UM ARQUIVO",
        "IDLabel": "ID",
        "ProductDesignationLabel": "Designação do Produto",
        "QuantityLabel": "Quantidade",
        "AdditionalInfoLabel": "Informações Adicionais",
        "RejectedLabel": "Rejeitado",
        "ExpiredLabel": "Expirado",
        "PurchaseCompletedLabel": "Compra Concluída",
        "ApprovedLabel": "Aprovado",
        "MultipleItemsLabel": "Vários Itens",
        "CreatedServiceLabel": "Serviço criado",
        "ServiceDescriptionLabel": "Descrição do Serviço",
        "ServiceOwnerLabel": "Proprietário do Serviço",
        "ServiceStatusLabel": "Status do Serviço",
        "PreviousLabel": "Anterior",
        "NextLabel": "Próximo",
        "FromLabel": "De",
        "ToLabel": "Para",
        "NoDataLabel": "Nenhum Dado Encontrado",
        "QlikDeletedLabel": "Ids Excluídos do Qlikview",
        "BulkAddLabel": "Adicionar IDs em Massa",
        "CountryLabel": "País",
        "TypeLabel": "Tipo",
        "ReasonLabel": "Razão",
        "AddLabel": "Adicionar",
        "CustomerLabel": "Cliente",
        "OrderBookMonitoringLabel": "Monitoramento do carteira de Pedidos",
        "DownloadReportLabel": "Baixar relatório",
        "SelectReportLabel": "Selecione um relatório",
        "PriceCalculationLabel": "Cálculo de preço",
        "ProductPriceInstructionLabel": "Para visualizar os preços dos produtos com impostos, pesquise o ID do COH na barra de pesquisa.",
        "DownloadErrorsLabels": "Download de Erros",
        "NoNetPriceLabel": "Sem preço líquido",
        "NoPriceWithTaxLabel": "Sem preço com impostos",
        "PricelistIDLabel": "ID da lista de preços",
        "AZIdLabel": "ID AZPartes",
        "SKULabel": "SKU",
        "NetPriceLabel": "Preço líquido",
        "DownloadPriceListLabel": "Lista de preços",
        "PriceWithTaxLabel": "Preço com Imposto",
        "GrossPriceLabel": "Preço Bruto",
        "AllProductsDescriptionLabel": "Esta aba contabiliza todos os produtos que estão na Tablea de preços dos clientes, mas não estão cadastrados nos catálogos.",
        "DownloadAutoLabel": "Baixar SKUs automotivo",
        "DownloadIndustrialLabel": "Baixar SKUs Industriais",
        "CreatedAtLabel": "Criado em",
        "LastUpdatesLabel": "Últimas atualizações",
        "NotFoundLabel": "Não Encontrado",
        "BrazilLabel": "Brasil",
        "ArgentinaLabel": "Argentina",
        "OrderLanel": "Ordem",
        "InvoiceCheckerLabel": "Verificador de NFs",
        "ENTLabel": "Status ENT",
        "DelayInBillingLabel": "Atraso no faturamento",
        "DelayInOrderDeliveryLabel": "atraso na entrega do pedido",
        "RBKLabel": "Status do RBK",
        "ShipmentDelayLabel": "Atraso na remessa",
        "REJLabel": "Status REJ",
        "CANLabel": "Status CAN",
        "NoImageLabel": "Sem Imagem",
        "NoRegisterLabel": "Sem Registro",
        "CustomersLabel": "Clientes",
        "ProductsLabel": "Produtos",
        "GlobalXMLDescriptionLabel": "Esta guia contem as descrições XML dos produtos SKF Global. As descrições deverão ser traduzidas para inglês, espanhol e português. ",
        "BulkTranslationLabel": "Tradução em massa",
        "LanguageLabel": "Idioma",
        "SearchDescriptionLabel": "Digite para pesquisar um produto…",
        "XMLLabel": "XML",
        "EnglishLabel": "Inglês",
        "SpanishLabel": "Espanhol",
        "PortugueseLabel": "Português",
        "PendingLabel": "Pendente",
        "CompleteLabel": "Completo",
        "ProductManageTabDescription": "Esta aba gerencia os produtos a serem exibidos na AZPartes.com. Os toogles executam os seguintes filtros",
        "NoAzIdDescription": "SEM ID AZPARTES -> Produtos não cadastrados no catalogo conforme Tabela de preços",
        "NoPackDescription": "SEM PACK -> Produtos sem código de embalagem",
        "NoDimensionsDescription": "SEM DIMENSÕES -> Produtos sem informações de dimensões ou peso cadastradas no COH",
        "NoAvailabilityDescription": "SEM DISPONIBILIDADE -> Produtos não disponíveis na SKF Brasil (DPC B)",
        "AddPackLabel": "Adicionar informações de embalagem",
        "DownloadMissingLabel": "Baixar dimensões faltantes",
        "SegmentLabel": "Segmento",
        "NoAZPartesIdLabel": "SEM ID AZPARTES",
        "NoPackLabel": "SEM PACK",
        "NoDimensionLabel": "SEM DIMESÕES",
        "NoAvailabilityLabel": "SEM DISPONIBILIDADE -> Produtos não disponíveis na SKF Brasil (DPC B)",
        "CatalogLabel": "Catálogo",
        "PackLabel": "Pack",
        "DimensionsLabel": "Dimensões",
        "AutomotiveLabel": "Automotivo",
        "IndustrialLabel": "Industrial",
        "CatalogDescription": "Esta aba contém os produtos que estão nos catalogos (automotivo e industrial) mais não estão em nenhuma tabela de preços do cliente",
        "DownloadDataLabel": "Baixar dados",
        "CreateTagLabel": "Criar tag",
        "TagNameLabel": "Nome da tag",
        "JIRAIDLabel": "ID JIRA",
        "JIRAStatusLabel": "Status no JIRA",
        "RequesterNameLabel": "Nome do solicitante",
        "RequesterEmailLabel": "E-mail do solicitante",
        "RequestDateLabel": "Data do pedido",
        "DetailsLabel": "Detalhes",
        "ServiceTriggerLabel": "NF de Serviço",
        "SourceLabel": "Fonte",
        "CustomerIDLabel": "Identificação do cliente",
        "COWNLabel": "Número do pedido do cliente",
        "TextLabel": "Texto",
        "EmailToLabel": "Email para",
        "EmailCCLabel": "Email CC",
        "TotalGrossPriceLabel": "Preço Bruto Total",
        "TotalQuantityLabel": "Quantidade Total ",
        "OrderLinesLabel": "Linhas de pedido",
        "ChargeIdLabl": "ID de cobrança",
        "UnitGrossPriceLabel": "Preço bruto unitário",
        "ServiceDepartmentLabel": "Departamento de serviço",
        "TotalGrossPricePerLineLabel": "Preço bruto total por linha",
        "ServiceDpsLabel": "Depto. de Serviço",
        "SubmitServiceLabel": "Enviar fatura de serviço",
        "SearchReportLabel": "Digite para pesquisar um relatorio",
        "DescriptionLabel": "Descrição",
        "RequestLabel": "Solicitar",
        "BearingTrackerLabel": "Rastreador de rolamento",
        "DynamicsLabel": "Dinâmica",
        "ErrorLabel": "Erro",
        "PasswordMatchLabel": "Senhas não conferem",
        "ValidEmailLabel": "Digite um email válido",
        "AddedLabel" : "Adicionado!",
        "UserSuccessfullyAddedLabel" : 'Usuário adicionado com Sucesso!',
        "SuccessLabel": "Sucesso",
        "UserSuccessfullyCreatedLabel" : 'Usuário Criado com Sucesso!',
        "FailedLabel": "Falha...",
        "CompleteFieldsLabel" : 'Por favor complete todos os campos obrigatórios',
        "InternalErrorLabel" : 'Erro interno, por favor tente novamente mais tarde',
        "ListOfCustomersLabel": "Lista de Clientes",
        "AddCustomerLabel" : 'Adicionar Cliente',
        "CompanyRegistrationlabel" : "Cadastro de Cliente",
        "RegisterCompanyLabel" : "Cadastrar Cliente",
        "UploadTemplateLabel" : "Ou faça o upload do template .xlsx do cliente",
        "DropHereLabel" : "ARRASTE AQUI",
        "OrLabel" : "OU",
        "SelectAFileLabel": "ESCOLHA O ARQUIVO",
        "CreateServiceLabel" : "Criar Serviço",
        "FromToLabel" : "De Para",
        "FromLabel" : "DE",
        "ToLabel" : "PARA",
        "SelectAReportLabel" : "Selecione um Relatório",
        "DownloadPriceListLabel" : "Baixar Price List",
        "TranslationTabLabel" : "Esta aba contém as descrições de produto SKF Global XML. Descrições devem ser traduzidas para o Inglês, Espanhol e Português",
        "MarketingTitle":"Módulo de Marketing",
        "BannersTitle":"Banners",
        "CampaignsTitle":"Campanhas",
        "ProductGuidesTitle":"Guias de Produto",
    },
    "es": {
        "AttentionLabel": "Atención",
        "EmailKeyWarning":"Los correos electrónicos deben ser únicos para cada usuario. Asegúrese de que ningún correo electrónico de la lista ya esté registrado como socio.",
        "Partner": "Socio",
        "PhoneNumberLabel": "Número de Teléfono",
        "ListOfPartners": "Lista de Socios",
        "AddPartner":"Agregar Socios",  
        "ListOfDistributors": "Lista de Distribuidores",
        "AddDistributor":"Agregar Distribuidores",
        "PartnerRegistrationLabel": "Registro de Socio",
        "FillInPartnerDetailsLabel": "Por favor complete los detalles del socio a continuación:",
        "RegisterPartnerLabel": "Registrar Socio",
        "ServicePartnerLabel":"Socio de Servicio",
        "Distributors": "Distribuidores",
        "Partners": "Socios",
        "suggestionsTitle":"Sugerencias de Usuarios",
        "exportLabel":"Exportar Tabla",
        "editColumnLabel":"Editar Columnas",
        "sendEmailsTitle":"Enviar Correos",
        "sendEmailsBtn":"Nuevo Correo",
        "sendEmailsH1":"Nombre del Correo",
        "sendEmailsH2":"Nombre del Remitente",
        "sendEmailsH3":"Correo del Remitente",
        "sendEmailsH4":"Fecha de Envio",
        "sendEmailsH5":"Status",
        "Key": "Es",
        "orderTitle": "Pedidos",
        "fieldSelect": "Seleccione un campo",
        "searchLabel": "Escribe para buscar…",
        "search": "Buscar",
        "SearchingLabel": "Buscando...",
        "policyTitle": "Políticas",
        "emailTitle": "Enviar Correo",
        "filters": "Filtros:",
        "usersTitle": "Usuarios",
        "countrySelection": "Seleccionar País",
        "subBusiness": "Subsegmento de Negocios",
        "dateTitle": "Fecha",
        "CohId": "ID COH",
        "CohShort": "Nombre del Cliente COH",
        "SkfOrderNumber": "Número de Pedido SKF",
        "NFNumber": "Número de NF",
        "NFKey": "Clave de NF",
        "customerOrderNumber": "Número de Pedido del Cliente",
        "PaymentMethod": "Método de Pago",
        "CreditLabel": "Límite de Crédito",
        "ClearLabel": "Limpiar",
        "AddOrderLabel": "Agregar Pedido",
        "DemoLabel": "Con Cuentas Demo",
        "OrderStatus": "Estado del Pedido",
        "AwaitingReg": "En espera de Registro de COH",
        "CancelledLabel": "Cancelado",
        "ProcessingLabel": "Processando",
        "CompletedLabel": "Terminado",
        "FunctionLabel": "Funciones",
        "AccessDescription": "Accesso al Panel de Administración",
        "RegistrationLabel": "Registros",
        "CompaniesLabel": "Compañías",
        "UsersLabel": "Usuarios (correo electrónico)",
        "AvailabilityLabel": "Disponibilidad",
        "APIServicesLabel": "Servicios API",
        "FromToLabel": "De Hasta",
        "QlikviewLabel": "Cartera Qlikview",
        "OrderbookLabel": "Cartera de Orden",
        "PricelistLabel": "Lista de precios",
        "PendingTasksLabel": "Tareas Pendientes",
        "OutOfCatalogLabel": "Fuera de Catálogo",
        "CatalogTransitionLabel": "Traducción de Catálogos",
        "ProductDisplayLabel": "Pantalla del Producto",
        "CatalogOportunityLabel": "Oportunidad de Catálogo",
        "TagManagerLabel": "Administrador de tag",
        "ServiceBillingLabel": "Facturación de Servicios",
        "ServiceInvoicePjLabel": "Automatización de facturación de servicios PJ",
        "ServicePjOrders": "Pedidos de servicio PJ",
        "ReportsLabel": "Informe",
        "OthersLabel": "Otros",
        "LogoutLabel": "Cerrar Sesión",
        "InvoiceNumberLabel": "Número de Factura",
        "InvoiceKeyLabel": "Clave de Factura",
        "BillingDateLabel": "Fecha de Facturación",
        "InsertOrderLabel": "Insertar Pedido",
        "FillInLabel": "Por favor complete la información a continuación:",
        "COHOrderIdLabel": "ID de Pedido de COH",
        "VerifyOderLabel": "Verificar Pedido",
        "AddUserLabel": "Agregar Usuario",
        "FillInUserLabel": "Complete el Siguiente formulario para registrar un nuevo usuario:",
        "NameLabel": "Nombre",
        "EmailLabel": "Correo Electrónico",
        "RoleLabel": "Cargo",
        "AdminLalbel": "Administrador",
        "PasswordLabel": "Contraseña:",
        "ConfirmPasswordLabel": "Confirmar contraseña:",
        "CreateUserLabel": "Crear Usuario",
        "ListOfCustomersLabel": "Lista de Clientes",
        "CnpjLabel": "CNPF",
        "AcceptMktLabel": "Acepta E-Mkt",
        "StatusLabel": "Estado",
        "FalseLabel": "FALSO",
        "TrueLabel": "VERDADERO",
        "InactiveLabel": "Inactivo",
        "ActiveLabel": "Activo",
        "PendingClientApprovalLabel": "Pendiente de Aprobación del Cliente",
        "ListOfUsersLabel": "Lista de Usuarios",
        "CohCompaniesLabel": "Empresas COH",
        "PhoneLabel": "Teléfono",
        "AwaitingConfLabel": "Pendiente de Confirmación",
        "UserRegistrationLabel": "Registro de Usuario",
        "CustomerNameLabel": "Nombre de Contacto con el Cliente",
        "CustomerPhoneLabel": "Teléfono de contacto con el Cliente",
        "CustomerEmailLabel": "Correio Electrónico de Notificación al Cliente",
        "RegisterUserLabel": "Registrar Usuario",
        "DownloadTemplateLabel": "Descargar Plantilla",
        "DownloadTemplateInstructionsLabel": "O Cargue la plantilla .xlsx de la Empresa",
        "DropInstructionsLabel": "SOLTAR AQUI O SELECIONA UN ARCHIVO",
        "IDLabel": "Identificación",
        "ProductDesignationLabel": "Desiginación de producto",
        "QuantityLabel": "Cantidad",
        "AdditionalInfoLabel": "Información adicional",
        "RejectedLabel": "Rechazado",
        "ExpiredLabel": "Venció",
        "PurchaseCompletedLabel": "Compra completada",
        "ApprovedLabel": "Aprobado",
        "MultipleItemsLabel": "Varios articulos",
        "CreatedServiceLabel": "Servicio creado",
        "ServiceDescriptionLabel": "Descripción del servicio",
        "ServiceOwnerLabel": "Propietario del servicio",
        "ServiceStatusLabel": "Estado del servicio",
        "PreviousLabel": "Anterior",
        "NextLabel": "Próximo",
        "FromLabel": "De",
        "ToLabel": "A",
        "NoDataLabel": "Datos no Encontrados",
        "QlikDeletedLabel": "Id eliminados de Qlikview",
        "BulkAddLabel": "Agregar IDs em masa",
        "CountryLabel": "País",
        "TypeLabel": "Tipo",
        "ReasonLabel": "Rázon",
        "AddLabel": "Agragar",
        "CustomerLabel": "Cliente",
        "OrderBookMonitoringLabel": "Monitoreo de la cartera de pedidos",
        "DownloadReportLabel": "Descargar Informe",
        "SelectReportLabel": "Seleccione un informe",
        "PriceCalculationLabel": "Cálculo de precios",
        "ProductPriceInstructionLabel": "Para ver los precios de los productos con impuestos, busque el ID COH en la barra de búsqueda.",
        "DownloadErrorsLabels": "Errores de descarga",
        "NoNetPriceLabel": "Sin precio neto",
        "NoPriceWithTaxLabel": "Sin precio con impuestos",
        "PricelistIDLabel": "ID de lista de precios",
        "AZIdLabel": "Id de AZPartes",
        "SKULabel": "SKU",
        "NetPriceLabel": "Precio neto",
        "DownloadPriceListLabel": "Lista de precios",
        "PriceWithTaxLabel": "Precio con Impuestos",
        "GrossPriceLabel": "Precio bruto",
        "AllProductsDescriptionLabel": "Esta pestaña contiene todos los productos que están en la Lista de Precios de Clientes, pero que no están registrados en los catálogos.",
        "DownloadAutoLabel": "Descargar SKU automotriz",
        "DownloadIndustrialLabel": "Descargar SKU industriales",
        "CreatedAtLabel": "Creado en",
        "LastUpdatesLabel": "Ultimas Actualizaciones",
        "NotFoundLabel": "No se encontró",
        "BrazilLabel": "Brasil",
        "ArgentinaLabel": "Argentina",
        "OrderLanel": "Orden",
        "InvoiceCheckerLabel": "Comprobador de facturas",
        "ENTLabel": "Estado ENT",
        "DelayInBillingLabel": "Retraso en la facturación",
        "DelayInOrderDeliveryLabel": "Retraso en la entrega del pedido",
        "RBKLabel": "Estado de RBK",
        "ShipmentDelayLabel": "Retraso del envío",
        "REJLabel": "Estado del REJ",
        "CANLabel": "Estado del CAN",
        "NoImageLabel": "Sin imágen",
        "NoRegisterLabel": "Sin Registro",
        "CustomersLabel": "Clientes",
        "ProductsLabel": "Productos",
        "GlobalXMLDescriptionLabel": "Esta pestaña contiene las descripciones XML de productos SKF Global. Las descripciones deben estar traducidas al inglés, español y portugués.",
        "BulkTranslationLabel": "Traducción en masa",
        "LanguageLabel": "Idioma",
        "SearchDescriptionLabel": "Escriba para buscar un producto…",
        "XMLLabel": "XML",
        "EnglishLabel": "Inglés",
        "SpanishLabel": "Español",
        "PortugueseLabel": "Portugués",
        "PendingLabel": "Pendiente",
        "CompleteLabel": "Completo",
        "ProductManageTabDescription": "Esta pestaña gestiona los productos que se mostrarán en AZPartes.com. Los toogles ejecutan los siguientes filtros:",
        "NoAzIdDescription": "SIN ID AZPARTES -> Productos no registrados en le catálogo según la lista de precios",
        "NoPackDescription": "SIN PACK -> Productos sin código de paquete",
        "NoDimensionsDescription": "SIN DIMENSIONES -> Productos sin información de dimensiones o peso registrados en COH",
        "NoAvailabilityDescription": "SIN DISPONIBILIDAD -> Productos no disponibles en SKF Brasil (DPC B)",
        "AddPackLabel": "Agregar Información del paquete",
        "DownloadMissingLabel": "Descargar dimensiones faltantes",
        "SegmentLabel": "Segmento",
        "NoAZPartesIdLabel": "SIN ID AZPARTES",
        "NoPackLabel": "SIN PACK",
        "NoDimensionLabel": "SIN DIMENSIONES",
        "NoAvailabilityLabel": "SIN DISPONIBILIDAD",
        "CatalogLabel": "Catálogo",
        "PackLabel": "Pack",
        "DimensionsLabel": "Dimensiones",
        "AutomotiveLabel": "Automotriz",
        "IndustrialLabel": "Industrial",
        "CatalogDescription": "Esta pestaña contiene los productis que están en los catálogos (automotriz e industrial) pero no están en ninguna lista de precios del cliente.",
        "DownloadDataLabel": "Download de Dados",
        "CreateTagLabel": "Crear tag",
        "TagNameLabel": "nombre de la tag",
        "JIRAIDLabel": "ID de JIRA",
        "JIRAStatusLabel": "Estado de JIRA",
        "RequesterNameLabel": "Nombre del solicitante",
        "RequesterEmailLabel": "Correo electrónico del solicitante",
        "RequestDateLabel": "Fecha de solicitud",
        "DetailsLabel": "Detalles",
        "ServiceTriggerLabel": "Factura de servicio",
        "SourceLabel": "Fuente",
        "CustomerIDLabel": "Identificación del cliente",
        "COWNLabel": "Número de pedido del cliente",
        "TextLabel": "texto",
        "EmailToLabel": "email para",
        "EmailCCLabel": "email CC",
        "TotalGrossPriceLabel": "precio bruto total",
        "TotalQuantityLabel": "cantidad total",
        "OrderLinesLabel": "Líneas de pedidosta de precios",
        "ChargeIdLabl": "ID de cargo",
        "UnitGrossPriceLabel": "precio bruto unitario",
        "ServiceDepartmentLabel": "departamento de servicio",
        "TotalGrossPricePerLineLabel": "Precio bruto total por línea",
        "ServiceDpsLabel": "Departamento de Servicio",
        "SubmitServiceLabel": "Enviar factura de servicio",
        "SearchReportLabel": "Escriba para buscar in informe",
        "DescriptionLabel": "Descripción",
        "RequestLabel": "Pedido",
        "BearingTrackerLabel": "Seguimiento de rodamientos",
        "DynamicsLabel": "Dinámica",
        "ErrorLabel": "Error",
        "PasswordMatchLabel": "Contraseñas no Coinciden",
        "ValidEmailLabel": "Escribelo un email válido",
        "AddedLabel" : "Agregado!",
        "UserSuccessfullyAddedLabel" : 'Usuário agregado exitosamente!',
        "SuccessLabel": "Éxito",
        "UserSuccessfullyCreatedLabel" : 'Usuário criado exitosamente!',
        "FailedLabel": "Falla...",
        "CompleteFieldsLabel" : 'Por favor complete todos los campos requeridos',
        "InternalErrorLabel" : 'Error interno, inténtalo de nuevo más tarde.',
        "ListOfCustomersLabel": "Lista de Clientes",
        "AddCustomerLabel" : 'Agregar Clientes',
        "CompanyRegistrationlabel" : "Registro de Cliente",
        "RegisterCompanyLabel" : "Registrar Cliente",
        "UploadTemplateLabel" : "O cargar la plantilla .xlsx del cliente",
        "DropHereLabel" : "BAJA AQUÍ",
        "OrLabel" : "O",
        "SelectAFileLabel": "SELECCIONA UN ARCHIVO",
        "CreateServiceLabel" : "Crear Servicio",
        "FromToLabel" : "De Para",
        "FromLabel" : "DE",
        "ToLabel" : "PARA",
        "SelectAReportLabel" : "Seleccione um Informe",
        "DownloadPriceListLabel" : "Descargar Price List",
        "TranslationTabLabel" : "Esta pestaña contiene las descripciones de productos SKF Global XML. Las descripciones deben estar traducidas al inglés, español y portugués.",
        "MarketingTitle":"Módulo de Marketing",
        "BannersTitle":"Banners",
        "CampaignsTitle":"Campañas",
        "ProductGuidesTitle":"Guías de Producto",
    }
};
  
  export default translations;