import Axios from "axios";

var URL = "";
var URL_EXTERNAL = "";
const { REACT_APP_ENV } = process.env;
console.log(`ENV: ${REACT_APP_ENV}`)


// URL = "https://xl89h69yx4.execute-api.us-east-1.amazonaws.com/production/";
// URL = "http://localhost:3000/";
URL = "https://5dv3gcm25a.execute-api.us-east-1.amazonaws.com/developer/"
URL_EXTERNAL = "https://92uwsoefhl.execute-api.us-east-1.amazonaws.com/developer/";


if (REACT_APP_ENV === "staging") {
    URL = "https://ym506rx5aj.execute-api.us-east-1.amazonaws.com/staging/";
    URL_EXTERNAL = "https://api.developer.azpartes.com/";
}

if (REACT_APP_ENV === "production") {
    URL = "https://xl89h69yx4.execute-api.us-east-1.amazonaws.com/production/";
    URL_EXTERNAL = "https://api.azpartes.com/";
}


const API = {
    logIn: function (source, userName, password) {
        return Axios.get(
            `${URL}admin/auth?username=${encodeURIComponent(userName)}&password=${encodeURIComponent(password)}`,
            { cancelToken: source.token }
        );
    },
    getApiToken: function (source, access_token) {
        return Axios.get(
            `${URL}api/service/token?access_token=${access_token}`,
            { cancelToken: source.token }
        );
    },
    submitService: function (source, payload, authorization) {
        return Axios.post(
            `${URL_EXTERNAL}service`,
            payload,
            { 'headers': { 'Authorization': authorization }, cancelToken: source.token  }
        );
    },
    submitPJService: function (source, payload, authorization) {
        return Axios.post(
            `${URL_EXTERNAL}service-pj`,
            payload,
            { 'headers': { 'Authorization': authorization }, cancelToken: source.token  }
        );
    },
    getToken: function (source) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}/api/service/gettoken`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getAdminUsers: function (source, page) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}users?page=${page}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getAdminUser: function (source, userId) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}user/${userId}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getExclusion: function(source, page, sortField, sortDirection){
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}exclusions?page=${page}&field=${sortField}&sort=${sortDirection}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getCompanyInfo: function(source, authorization, company_id){
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL_EXTERNAL}service-pj/company/info?customer_id=${company_id}`,
            { 'headers': { 'Authorization': authorization }, cancelToken: source.token  }
        )
    },
    editExlusion: function(source, payload){
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.put(
            `${URL}exclusions`,
            payload,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getMaintenance: function(source, maintenanceId) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}maintenance`,
            {
                params: { maintenance_id: maintenanceId },
                headers: { 'Authorization': auth },
                cancelToken: source.token
            }
        )
    },
    listAllMaintenances: function(source) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}maintenances`,
            {
                headers: { 'Authorization': auth },
                cancelToken: source.token
            }
        )
    },
    getListOfDistributors: function(source) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}service-partner/distributors`,
            {
                headers: { 'Authorization': auth },
                cancelToken: source.token
            }
        )
    },
    getListOfPartners: function(source) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}service-partner/partners`,
            {
                headers: { 'Authorization': auth },
                cancelToken: source.token
            }
        )
    },
    updateMaintenance: function(source, maintenanceId, payload) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        console.log(`${URL}maintenance?maintenance_id=${maintenanceId}`)
        return Axios.put(
            `${URL}maintenance?maintenance_id=${maintenanceId}`,
            payload,
            {
                params: { maintenance_id: maintenanceId },
                headers: { 'Authorization': auth },
                cancelToken: source.token
            }
        )
    },
    postMaintenance: function(source, payload) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(
            `${URL}maintenance`,
            payload,
            {
                headers: { 'Authorization': auth },
                cancelToken: source.token
            }
        )
    },
    createExlusion: function(source, payload){
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(
            `${URL}exclusions`,
            payload,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    deleteExlusion: function(source, id){
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.delete(
            `${URL}exclusions?id=${id}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getAPIServices: function (source, page) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}/api/services?page=${page}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getAPIService: function (source, service_id) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}/api/service/${service_id}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    generateServiceToken: function (source, service_id, user_email, user_tag) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(
            `${URL}api/service/token`,
            { 
                data: {
                    "service_id": service_id,
                    "user_email": user_email,
                    "tag": user_tag
                }
            },
              { 'headers': { 'Authorization': auth }, cancelToken: source.token 
            }
        )
    },
    postService: function (source, name, email) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(
            `${URL}api/service`,
            { 
                data: {
                    "description": name,
                    "service_owner": email
                }
            },
              { 'headers': { 'Authorization': auth }, cancelToken: source.token 
            }
        )
    },
    postServiceUser: function (source, name, email) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(
            `${URL}api/service/user`,
            { 
                data: {
                    "name": name,
                    "email": email
                }
            },
              { 'headers': { 'Authorization': auth }, cancelToken: source.token 
            }
        )
    },
    revokeTokenAcces: function (source, service_token_id) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.delete(
            `${URL}api/service/token?service_token_id=${service_token_id}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source && source.token }
        )
    },
    getListOfOrders: function (source, page, sorting, country, sub_business, demo) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            // `${URL}orders?page=${page}`,
            `${URL}orders?page=${page}&sorting=${sorting}&country=${country}&sub_business=${sub_business}&demo=${demo}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getListOfSuggestions: function (source) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            // `${URL}orders?page=${page}`,
            ` ${URL}/suggestions`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getSuggestion: function (source, suggestion_id) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            // `${URL}orders?page=${page}`,
            ` ${URL}/suggestions/${suggestion_id}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    putSuggestion: function (source, payload, sugestions_id) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.put(
            `${URL}suggestions/${sugestions_id}`,
            payload,
              { 'headers': { 'Authorization': auth }, cancelToken: source.token 
            }
        )
    },
    postSuggestionComment: function (source, payload) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(
            ` ${URL}/suggestions/comment`,
            payload,
              { 'headers': { 'Authorization': auth }, cancelToken: source.token 
            }
        )
    },
    getSuggestionComments: function (source, suggestionId) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            ` ${URL}/suggestions/comment/${suggestionId}`,
            { headers: { 'Authorization': auth }, cancelToken: source.token }
        );
    },
    getListOfTags: function (source, page, sorting, search_field = '', search_value = '') {
        let auth = localStorage.getItem('token');
        var url_string = `${URL}tags?page=${page}&sorting=${sorting}`;
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        if(search_field !== '' && search_value !== ''){
            url_string = `${URL}tags?page=${page}&sorting=${sorting}&search_param=${search_field}&search_value=${search_value}`
        }
        return Axios.get(
            // `${URL}orders?page=${page}`,
            url_string,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    removeTag: function (source, id) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.put(
            `${URL}tag`,
            { request_id: id },
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    postTag: function (source, body) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(
            `${URL}tag`,
            body,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getTag: function (source, id) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}tag/${id}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getListOfCapgemini: function (source, page, status) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            // `${URL}orders?page=${page}`,
            `${URL}financial/orders?page=${page}&status=${status}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getListOfFromTo: function (source, field = undefined, search = undefined) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        if (field === undefined || search === undefined) {
            return Axios.get(
                // `${URL}orders?page=${page}`,
                `${URL}products/invalid`,
                { 'headers': { 'Authorization': auth }, cancelToken: source.token }
            )
        } else {
            return Axios.get(
                // `${URL}orders?page=${page}`,
                `${URL}products/invalid?field=${field}&value=${search}`,
                { 'headers': { 'Authorization': auth }, cancelToken: source.token }
            )

        }
    },
    getListOfFromToProduct: function (source, productId) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}product/invalid/${productId}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    putFromToProduct: function (source, productId, value, inactivate = null) {
        let auth = localStorage.getItem('token');
        var inactivate_str = inactivate ? `?inactivate=1` : '';
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.put(
            `${URL}product/invalid/${productId}${inactivate_str}`,
            { product_to: value },
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getSearchListOfOrders: function (source, page, field, value, country, sub_business, demo ,sorting) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            // `${URL}orders/search?page=${page}&field=${field}&value=${value}`,
            `${URL}orders/search?page=${page}&field=${field}&value=${value}&sorting=${sorting}&country=${country}&sub_business=${sub_business}&demo=${demo}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getSearchListOfServiceInvoice: function (source, page, field, value) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL_EXTERNAL}service-pj/services?search_field=${field}&search_term=${value}&page=${page}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getCohOrder: function (source, coh) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}order/coh/${coh}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    insertOrder: function (source, coh, cco) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(
            // `${URL}order/create?coh=${coh}&cco=${cco}`,
            `${URL}order/create?coh=${coh}&cco=${cco}`,
            { data: "" },
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getOrder: function (source, orderId) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            // `${URL}order/${orderId}`,
            `${URL}order/${orderId}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getOrderNF: function (id, source) {
        let auth = localStorage.getItem('token');
        return fetch(`${URL}order/${id}/nf`, {
            // return fetch(`${URL}order/${id}/nf`, { 
            method: 'get',
            headers: new Headers({
                'Authorization': auth
            })
        });
    },
    cancelOrder: function (source, orderId) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.put(
            // `${URL}order/cancel/${orderId}`,
            `${URL}order/cancel/${orderId}`,
            { data: '' },
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getNotifications: function (source) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}notifications`,
            // `${URL}order/${orderId}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getNotification: function (source, notificationId) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}notification/${notificationId}`,
            // `${URL}order/${orderId}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getInvoiceMonitoring: function(source){
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}monitoring/invoice`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getOrderMonitoring: function(source, type){
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}monitoring/order?type=${type}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getProdCustMonitoring: function(source, type){
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}monitoring/product_customer?type=${type}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getCancelledMonitoring: function(source, type){
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}monitoring/cancelled?type=${type}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    discardCancelled: function(source, id){
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.put(
            `${URL}monitoring/cancelled/${id}`,
            {},
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    addSystemUser: function (payload) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(`${URL}user`,
            payload,
            { 'headers': { 'Authorization': auth } }
        );
    },
    addPolicy: function (payload) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(`${URL}policy`,
            payload,
            { 'headers': { 'Authorization': auth } }
        );
    },
    getPolicies: function (source, dropDownChange) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}policies?type=${dropDownChange}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getListOfCustomers: function (source, page) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}companies?page=${page}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getListOfEmails: function (source, page) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}emails?page=${page}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    sendEmail: function (emailInfo) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(
            `${URL}email`,
            // `${URL}company`,
            { data: emailInfo },
            { 'headers': { 'Authorization': auth } }
        )
    },
    getSearchListOfCustomers: function (source, page, field, value) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}companies/search?page=${page}&field=${field}&value=${value}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getListOfCustomerUsers: function (source, page) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}azpartes/users?page=${page}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getSearchListOfCustomerUsers: function (source, page, field, value) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}azpartes/users/search?page=${page}&field=${field}&value=${value}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getCustomerDetails: function (source, customerId) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}company/${customerId}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getCustomerUserDetails: function (source, customerId) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}azpartes/user/${customerId}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getListOfAvailibilities: function (source, page) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}availabilities?page=${page}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getSearchListOfAvailabilities: function (source, page, field, value) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            // `${URL}availabilities/search?page=${page}&field=${field}&value=${value}`,
            `${URL}availabilities/search?page=${page}&field=${field}&value=${value}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getAvailability: function (source, availabilityId) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}availability/${availabilityId}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getIndustrialCatalog: function (source, currentPage, field, value) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}products/translation?page=${currentPage}&field=${field}&value=${value}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )

        // return [{ key: 1, xml: "Bearing", SKU: "HMV 18233983", status: "complete", english: true, spanish: true, portuguese: true },
        // { key: 2, xml: "Bearing", SKU: "HMV 28233982", status: "pending", english: false, spanish: false, portuguese: true },
        // { key: 3, xml: "Bearing", SKU: "HMV 38233981", status: "pending", english: true, spanish: false, portuguese: true },
        // { key: 4, xml: "Bearing", SKU: "HMV 48233980", status: "pending", english: true, spanish: false, portuguese: true },
        // { key: 5, xml: "Bearing", SKU: "HMV 58233984", status: "pending", english: true, spanish: false, portuguese: false }]
    },
    getIndustrialCatalogProduct: function (source, productId) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}products/translation/${productId}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    postIndustrialCatalogTranslation: function (source, productId, field, translation) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(
            // `${URL}products/translation`,
            `${URL}products/translation`,
            {
                data: {
                    "text": translation,
                    "field": field,
                    "product": productId
                }
            },
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getIndustrialCatalogInfo: function (source, page, search_value) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}products/missing/industrial?page=${page}&search=${search_value}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getCatalogOpprtunity: function (source, page, search_value, segment, catalog) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            // `${URL}products/opportunity?page=${page}&search=${search_value}`,
            `${URL}products/opportunity?page=${page}&search=${search_value}&segment=${segment}&catalog=${catalog}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getProductDisplay: function (source, page, search, segment, pricelist, not_registered, no_dimensions, no_catalog, no_availability, no_pack) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}products/display?page=${page}&search=${search}&segment=${segment}&pricelist=${pricelist}&not_registered=${not_registered}&no_dimensions=${no_dimensions}&no_catalog=${no_catalog}&no_availability=${no_availability}&no_pack=${no_pack}`,
            // `${URL}products/display?page=${page}&segment=${segment}&segment=${pricelist}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    putProductPack: function (source, pack_data) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.put(
            `${URL}products/pack`,
            { data: pack_data },
            // `${URL}products/display?page=${page}&segment=${segment}&segment=${pricelist}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    putBulkTranslation: function (source, translations) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.put(
            `${URL}products/bulk/translation`,
            { data: translations },
            // `${URL}products/display?page=${page}&segment=${segment}&segment=${pricelist}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getPackInformation: function (source) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}products/pack/missing`,
            // `${URL}products/display?page=${page}&segment=${segment}&segment=${pricelist}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getMissingTranslation: function (source) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}translation/missing`,
            // `${URL}products/display?page=${page}&segment=${segment}&segment=${pricelist}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getTaxErrorInformation: function (source) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}price/errors`,
            // `${URL}products/display?page=${page}&segment=${segment}&segment=${pricelist}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getPriceWithTax: function (source, company, page) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}company/pricelist?company=${company}&page=${page}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getMissingCatalog: function (source, industry) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}catalog/missing?industry=${industry}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getServiceReport: function (source, serviceId) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(
            `${URL}api/service/report?service_id=${serviceId}`,
            {},
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getMissingDimensions: function (source) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}/missing/dimensions`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getPriceCalculation: function (source, page, company, filter) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}products/price/${company}?page=${page}&filter=${filter}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getReports: function (source, page) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}reports?page=${page}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getReport: function (source, report_id) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}report?report_id=${report_id}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getPartner: function (source, id) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}service-partner/partner?id=${id}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    updatePartner: function (payload) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.put(
            `${URL}service-partner/partner`,
            payload,
            { 'headers': { 'Authorization': auth } }
        )
    },
    getDistributor: function (source, id) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}service-partner/distributor?id=${id}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    getPartnerStats: function (source) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}service-partner/stats`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    updateDistributor: function (payload) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.put(
            `${URL}service-partner/distributor`,
            payload,
            { 'headers': { 'Authorization': auth } }
        )
    },
    getPriceDetails: function (source, company, product) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}product/price/${company}/${encodeURIComponent(product)}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    // getNotifications: function (bot) {

    //     return [{ key: 1, id: 1023, number: 1, title: "Product Price Calculation Error", details: "Product VKBC7201 had an error while calculatin the Price With Taxes", date: "01/03/2022" },
    //     { key: 2, id: 1026, number: 1, title: "Product Price Calculation Error", details: "Product VKBC7201 had an error while calculatin the Price With Taxes", date: "01/03/2022" },
    //     { key: 3, id: 1024, number: 3, title: "Product Price Calculation Error", details: "Product VKBC7201 had an error while calculatin the Price With Taxes", date: "01/03/2022" },
    //     { key: 4, id: 1025, number: 5, title: "Product Price Calculation Error", details: "Product VKBC7201 had an error while calculatin the Price With Taxes", date: "01/03/2022" }]

    // },
    registerUserCustomer: function (customerInfo) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(
            `${URL}azpartes/user`,
            // `${URL}company`,
            { data: customerInfo },
            { 'headers': { 'Authorization': auth } }
        )
    },
    registerCustomer: function (customerInfo) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(
            `${URL}company`,
            // `${URL}company`,
            { data: customerInfo },
            { 'headers': { 'Authorization': auth } }
        )
    },
    registerDistributor: function (distributorInfo) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(
            `${URL}service-partner/distributors`,
            // `${URL}company`,
            {"distributors":distributorInfo},
            { 'headers': { 'Authorization': auth } }
        )
    },
    deleteDistributor: function (source, distributorInfo) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.delete(
            `${URL}service-partner/distributor?email=${distributorInfo.email}`,
            // `${URL}company`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    deletePartner: function (source, distributorInfo) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.delete(
            `${URL}service-partner/partner?email=${distributorInfo.email}`,
            // `${URL}company`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    registerPartner: function (partnerInfo) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(
            `${URL}service-partner/partners`,
            // `${URL}company`,
            {"users":partnerInfo},
            { 'headers': { 'Authorization': auth } }
        )
    },
    putCohIds: function (payload) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.put(
            `${URL}azpartes/user`,
            payload,
            { 'headers': { 'Authorization': auth } }
        )
    },
    companyContact: function (id, name, email, phone) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.put(
            `${URL}company/contact/${id}`,
            {
                data: {
                    name: name,
                    email: email,
                    phone: phone
                }
            },
            { 'headers': { 'Authorization': auth } }
        )
    },
    availibilityResponse: function (availibilityData) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(
            `${URL}availability/response`,
            { data: availibilityData },
            { 'headers': { 'Authorization': auth } }
        )
    },
    availibilityResendEmail: function (availability_id, cc_email) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(
            `${URL}availability/response/resend`,
            { data: {"availability_id": availability_id, "cc_email":cc_email} },
            { 'headers': { 'Authorization': auth } }
        )
    },
    availibilityUpdate: function (availibilityData, id) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.put(
            `${URL}availability/${id}`,
            { data: availibilityData },
            { 'headers': { 'Authorization': auth } }
        )
    },
    availibilityReject: function (id, payload) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(
            `${URL}availability/reject/${id}`,
            { data: payload },
            { 'headers': { 'Authorization': auth } }
        )
    },
    validateProducts: function (payload) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(
            `${URL}products/pricelist`,
            { data: payload },
            { 'headers': { 'Authorization': auth } }
        )
    },
    hardReset: function (companyId) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.put(
            `${URL}azpartes/user/password?user_id=${companyId}`,
            { data: '' },
            { 'headers': { 'Authorization': auth } }
        )
    },
    sendRecover: function (email) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.post(
            `${URL}azpartes/user/recovery`,
            { email: email },
            { 'headers': { 'Authorization': auth } }
        )
    },
    inactivateUser: function (companyId) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.put(
            `${URL}company/inactivate/${companyId}`,
            { data: '' },
            { 'headers': { 'Authorization': auth } }
        )
    },
    inactivateCustomerUser: function (companyId) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.put(
            `${URL}azpartes/user/inactivate?user_id=${companyId}`,
            { data: '' },
            { 'headers': { 'Authorization': auth } }
        )
    },
    activateCustomerUser: function (companyId) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.put(
            `${URL}azpartes/user/activate?user_id=${companyId}`,
            { data: '' },
            { 'headers': { 'Authorization': auth } }
        )
    },
    reactivateUser: function (companyId, skfId) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.put(
            `${URL}company/reactivate?company_id=${companyId}&skf_id=${skfId}`,
            { data: '' },
            { 'headers': { 'Authorization': auth } }
        )
    },
    getCapgeminiTask: function (source, id) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.get(
            `${URL}financial/order/${id}`,
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    updateCapgeminiStatus: function (source, id) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.put(
            `${URL}financial/status/${id}`,
            {},
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    updateDownloadedInvoice: function (source, id) {
        let auth = localStorage.getItem('token');
        if (!auth) return new Promise(function (resolve, reject) { reject(new Error('Not logged in')) });
        return Axios.put(
            `${URL}financial/nf?nf_id=${id}`,
            {},
            { 'headers': { 'Authorization': auth }, cancelToken: source.token }
        )
    },
    recoverPassword: function (source, email) {
        return Axios.post(
            `${URL}admin/auth/recover`,
            { email: email },
            { cancelToken: source.token }
        );
    },
    changePassword: function (source, token, password) {
        return Axios.put(
            `${URL}admin/auth/recover`,
            {
                token: token,
                password: password
            },
            { cancelToken: source.token }
        );
    },
    unlinkUser: function (source, payload) {
        return Axios.put(
            `${URL}company/unlink`,
            payload,
            { cancelToken: source.token }
        );
    },
    cancelToken: function () {
        const cancelToken = Axios.CancelToken;
        return cancelToken.source();
    },
    cancel: function (token) {
        if (token) token.cancel('cancelling');
    }
};

export default API; 