import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../utils/ContextProvider";
import { NavLink } from "react-router-dom";
import './style.css'
import { useLanguage } from '../../context/LanguageContext';

function Sidebar(props) {

    const context = useContext(AppContext);
    const role = context.getRole;
    const [toggle, setToggle] = useState({ visibility: "visible" })
    const [isClicked, setIsClicked] = useState(false);
    const [newWidth, setNewWidth] = useState(window.innerWidth);
    const [external, setExternal] = useState(true)
    const { translate } = useLanguage();

    window.addEventListener('resize', function (event) {
        setNewWidth(window.innerWidth);
    });

    useEffect(() => {
        if (context.getLogin) {
            setExternal(false)
        }
    }, [context])


    useEffect(() => {

        setToggle(props.clicked);

    }, [props.clicked])


    return (
        <div>
            {!external && <div className="flex-row sidebar-width h-full">
                {
                    newWidth < 1080 &&
                    <div style={toggle} onClick={() => props.onClick()} className="fixed top-0 left-0 z-10 w-full h-full bg-darkblue opacity-50">
                    </div>
                }
                <div style={toggle} className={newWidth > 1080 ? "flex-row h-full" : "fixed z-10"}>
                    <div className="flex-row">
                        <div className={"bg-gray-200 flex-col p-6 " + (newWidth < 1080 ? "min-h-screen" : "min-h-screen")}>
                            <div className="font-bold flex items-center">
                                <h1>AZPartes.com</h1>

                                {
                                    newWidth < 1080 &&
                                    <div onClick={() => { props.onClick() }} className="flex-row ml-10 cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x cursor-pointer" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                        </svg>
                                    </div>}
                            </div>
                            <div className="flex-col mt-14 mb-6">
                            <h1 className=" text-lg font-medium faded" >{translate('ServicePartnerLabel')}</h1>
                            </div>
                                <div>
                                    <div className="pl-6 pr-12">
                                        <NavLink to="/partner/stats"><div className="py-2 font-light text-lg">Service Partner</div></NavLink>
                                        <NavLink to="/distributors"><div className="py-2 font-light text-lg">{translate('Distributors')}</div></NavLink>
                                        <NavLink to="/partners"><div className="py-2 font-light text-lg">{translate('Partners')}</div></NavLink>
                                    </div>
                                </div>

                            <div className="flex-col mt-14 mb-6">
                                <h1 className=" text-lg font-medium faded" >{translate('OthersLabel')}</h1>
                            </div>
                            <div className="pl-6">
                                <div className="py-1 font-light cursor-pointer text-lg" onClick={() => { context.logOut() }}>{translate('LogoutLabel')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default Sidebar;